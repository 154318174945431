import { Mapper } from '../../../../../hexagon/infra/Mapper';
import { TVehicleState } from '../../../../../hexagon/interfaces';
import { AutobizRecordVehicleStateDto } from '../dtos/recordVehicleStateDto';

export class RecordVehicleStateMapper implements Mapper<TVehicleState> {
    static toAutobiz(
        identifier: string,
        recordUid: string,
        state: TVehicleState,
    ): AutobizRecordVehicleStateDto {
        let running;
        if (state.running === 'yes') running = 1;
        else if (state.running === 'no') running = 3;

        return {
            identifier,
            recordUid,
            imported: state.imported,
            serviceHistory: state.history,
            firstHand: state.firstHand,
            serviceInvoice: state.serviceInvoice,
            running,
            notRollingReason: state.notRollingReason,
            notRollingDescription: state.notRollingDescription || undefined,
            batteryRent: state.batteryRent,
        };
    }
}
