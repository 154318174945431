import { isLeft } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { DealerGateway } from '../../gateways/dealerGateway.interface';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import { dislayErrorUseCase } from '../displayError/displayError.useCase';
import { getDealerListUseCase } from '../getDealerList/getDealerList.useCase';
import { getRecordUseCase } from '../getRecord/getRecord.useCase';
import { submitNotRollingVehicleUseCase } from '../submitNotRollingVehicle/submitNotRollingVehicle.useCase';
import { updateDealerListUseCase } from '../updateDealerList/updateDealerList.useCase';
import * as actionCreators from './actionCreators';

export const saveVehicleAndUserInformationsUseCase =
    (): ThunkResult<void> =>
    async (
        dispatch,
        getState,
        { recordGateway }: { recordGateway: RecordGateway; dealerGateway: DealerGateway },
    ) => {
        dispatch(actionCreators.Actions.saveVehicleAndUserInformationsSaving());

        const {
            config,
            journeyType,
            origin,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent,
            gclid,
            utmTerm,
            fbclid,
            msclkid,
        } = getState().client;

        const {
            make,
            model,
            month,
            year,
            fuel,
            body,
            door,
            gear,
            engine,
            version,
            mileage,
            grayCardDate,
        } = getState().form.vehicle;

        const { mediaSource } = getState().form.vehicleState;

        // Saving vehicle and create record
        const resultVehicle = await recordGateway.saveVehicleInformation(
            config.identifier,
            journeyType,
            {
                make,
                model,
                month,
                year,
                fuel,
                body,
                door,
                gear,
                engine,
                version,
                mileage,
                grayCardDate,
                registration: getState().form.registration,
            },
            origin,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent,
            gclid,
            utmTerm,
            fbclid,
            msclkid,
            mediaSource,
        );

        if (isLeft(resultVehicle)) {
            dispatch(dislayErrorUseCase('create_record_failed'));
            return dispatch(actionCreators.Actions.saveVehicleAndUserInformationsFailed());
        }
        const recordUid = resultVehicle.right.uid;

        dispatch(getRecordUseCase(recordUid));

        // Saving State

        const {
            history,
            imported,
            running,
            notRollingReason,
            notRollingDescription,
            firstHand,
            serviceInvoice,
            batteryRent,
        } = getState().form.vehicleState;

        const resultState = await recordGateway.saveVehicleStateInformation(
            config.identifier,
            recordUid,
            {
                history,
                imported,
                running,
                notRollingReason,
                notRollingDescription,
                firstHand,
                serviceInvoice,
                batteryRent,
            },
        );

        if (isLeft(resultState)) {
            return dispatch(dislayErrorUseCase('create_state_failed'));
        }

        // saving user information
        const { email, phone, zipCode } = getState().form.particular;
        const { deviceType } = getState().client;

        const params = {
            phone,
            phone2: phone,
            email,
            zipCode,
            deviceType,
        };

        if (params.phone === '') delete params.phone;
        if (params.phone === '') delete params.phone2;
        if (params.phone2 === '') delete params.phone2;

        const resultUser = await recordGateway.saveUserInformation(
            config.identifier,
            recordUid,
            params,
        );

        if (isLeft(resultUser)) {
            return dispatch(dislayErrorUseCase('create_particular_failed'));
        }

        const { purchaseProject, buyingProjectMake } = getState().form.vehicleState;

        if (purchaseProject) {
            // Updating purchase project
            const resultPurchaseProject = await recordGateway.updatePurchaseProject(
                config.identifier,
                recordUid,
                purchaseProject,
                buyingProjectMake,
            );

            if (isLeft(resultPurchaseProject)) {
                return dispatch(dislayErrorUseCase('update_purchase_project_failed'));
            }
        }

        let quotationStatus = false;

        // Running / or VOI vehicle
        if (running === 'yes') {
            // create quotation
            const resultQuotation = await recordGateway.createQuotation(
                config.identifier,
                recordUid,
            );
            await dispatch(getRecordUseCase(recordUid));

            if (isLeft(resultQuotation)) {
                return dispatch(dislayErrorUseCase('create_quotation_failed'));
            }

            if (resultQuotation.right) {
                // quotation status == true
                quotationStatus = true;
            }

            // Redirect when purchase project VN
            const { link: makeLink } = getState().buyingProjectMake;

            if (makeLink) {
                window.open(`${makeLink}?c2b=${recordUid}`, '_parent');
                return dispatch(
                    actionCreators.Actions.saveVehicleAndUserInformationsSaved(resultVehicle.right),
                );
            }
        } else {
            await dispatch(getRecordUseCase(recordUid));
            await dispatch(submitNotRollingVehicleUseCase(phone));
        }

        if (quotationStatus) {
            await dispatch(getDealerListUseCase(recordUid));
            await dispatch(updateDealerListUseCase());

            const { status: dealerListStatus } = getState().dealer.dealerList;

            if (dealerListStatus) {
                const { isRad } = getState().record.data;
                const { closePOS } = getState();

                await recordGateway.sendMail(
                    config.identifier,
                    recordUid,
                    isRad && !closePOS
                        ? 'restitution_mail_with_hbs'
                        : 'restitution_mail_without_hbs',
                );
            } else {
                await recordGateway.sendMail(config.identifier, recordUid, 'empty_network_mail');
            }
        } else {
            await recordGateway.sendMail(config.identifier, recordUid, 'cq_mail');
        }

        return dispatch(
            actionCreators.Actions.saveVehicleAndUserInformationsSaved(resultVehicle.right),
        );
    };
